import React from 'react';
import './guideBook.css';
import { StaticImage } from 'gatsby-plugin-image';
import AppButton from '../CustomComponents/app-button';
import HubspotForm from 'react-hubspot-form';

const GuideBookSection = () => {
    return (
        <>
            <div className="book-container">
                <div className="line-1"></div>
                <div className="line-2"></div>

                <div className="book-img">
                    <StaticImage
                        alt="genrative-book"
                        src="../../assets/guidebook/guidebook-img.png"
                        loading="eager"
                        className="guide-img"
                        placeholder="none"
                    />
                </div>
                <div className="text-section">
                    <h1 className="guide-h1">THE BUSINESS IMPACT AND OPPORTUNITY OF GENERATIVE AI</h1>
                    <p className="guide-p">
                        An AI/Hyperautomation Guidebook written in collaboration with{' '}
                        <span className="guide-span">Acceleration Economy</span>
                    </p>

                    <div className="guide-form">
                        <HubspotForm
                            portalId="20925239"
                            formId="0953d691-9c34-4167-bab6-73f381a98c29"
                            onSubmit={() => console.log('Submit!')}
                            loading={<div>Loading...</div>}
                        />
                    </div>
                </div>
            </div>

            <div className="guide-form-2">
                <h4>Grab a copy now!</h4>

                <HubspotForm
                    portalId="20925239"
                    formId="0953d691-9c34-4167-bab6-73f381a98c29"
                    onSubmit={() => console.log('Submit!')}
                    loading={<div>Loading...</div>}
                />
            </div>
        </>
    );
};

export default GuideBookSection;
